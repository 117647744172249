import React from "react";
import { getLessonList, getKlassList, getMegamaList, getStudentList, getLessonCategoryList } from "../../../_actions/list_actions";
import TypeAhead from "../TypeAhead";

export const createFields = (state, setters) => ({
    dateRange: (
        <div className="form-group row">
            <label className="m-1 col-sm-2">תאריכים</label>
            <div className="col">
                <input
                    type="date"
                    id="fromDate"
                    name="fromDate"
                    className="form-control"
                    value={state.fromDate}
                    onChange={(e) => setters.setFromDate(e.target.value)}
                />
            </div>
            <label className="m-1 col-sm-1">עד</label>
            <div className="col">
                <input
                    type="date"
                    id="toDate"
                    name="toDate"
                    className="form-control"
                    value={state.toDate}
                    onChange={(e) => setters.setToDate(e.target.value)}
                />
            </div>
        </div>
    ),
    klass: (
        <div className="form-group row">
            <label htmlFor="klass" className="col-sm-2">כיתות</label>
            <div className="col">
                <TypeAhead
                    id="klass"
                    multiple={true}
                    placeholder={"בחר כיתה..."}
                    value={state.klass}
                    setValue={setters.setKlass}
                    getOptions={getKlassList}
                />
            </div>
        </div>
    ),
    megama: (
        <div className="form-group row">
            <label htmlFor="megama" className="col-sm-2">מגמות</label>
            <div className="col">
                <TypeAhead
                    multiple={true}
                    id="megama"
                    placeholder={"בחר מגמה..."}
                    value={state.megama}
                    setValue={setters.setMegama}
                    getOptions={getMegamaList}
                />
            </div>
        </div>
    ),
    student: (
        <div className="form-group row">
            <label htmlFor="student" className="col-sm-2">תלמידות</label>
            <div className="col">
                <TypeAhead
                    id="student"
                    multiple={true}
                    placeholder={"בחר תלמידה..."}
                    value={state.student}
                    setValue={setters.setStudent}
                    getOptions={(query) => getStudentList(query)}
                />
            </div>
        </div>
    ),
    lesson: (
        <div className="form-group row">
            <label htmlFor="lesson" className="col-sm-2">שיעורים</label>
            <div className="col">
                <TypeAhead
                    id="lesson"
                    multiple={true}
                    placeholder={"בחר שיעור..."}
                    value={state.lesson}
                    setValue={setters.setLesson}
                    getOptions={(query) => getLessonList(query)}
                />
            </div>
        </div>
    ),
    lessonWithAll: (
        <div className="form-group row">
            <label htmlFor="lesson" className="col-sm-2">שיעורים</label>
            <div className="col">
                <TypeAhead
                    id="lesson"
                    multiple={true}
                    disabled={state.allLessons}
                    placeholder={"בחר שיעור..."}
                    value={state.lesson}
                    setValue={setters.setLesson}
                    getOptions={(query) => getLessonList(query)}
                />
            </div>
            <div className="col-sm-3 d-flex align-items-center">
                <input
                    type="checkbox"
                    id="rememberMe"
                    name="rememberMe"
                    className="form-check-inline"
                    value={state.allLessons}
                    onChange={(e) => setters.setAllLessons(e.target.checked)}
                />
                <label htmlFor="rememberMe" className="d-inline m-0">
                    בחר את כל השיעורים המתאימים
                </label>
            </div>
        </div>
    ),
    lessonCategory: (
        <div className="form-group row">
            <label htmlFor="lessonCategory" className="col-sm-2">קטגוריות שיעור</label>
            <div className="col">
                <TypeAhead
                    multiple={true}
                    id="lessonCategory"
                    placeholder={"בחר קטגוריה..."}
                    value={state.lessonCategory}
                    setValue={setters.setLessonCategory}
                    getOptions={getLessonCategoryList}
                />
            </div>
        </div>
    ),
    name: (
        <div className="form-group row">
            <label htmlFor="name" className="col-sm-2">שם תלמידה</label>
            <div className="col">
                <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="הכנס שם תלמידה"
                    className="form-control"
                    value={state.name}
                    onChange={(e) => setters.setName(e.target.value)}
                />
            </div>
        </div>
    ),
    secondRange: (
        <div className="form-group row">
            <label className="m-1 col-sm-2">טווח שניות</label>
            <div className="col">
                <input
                    type="number"
                    id="fromSeconds"
                    name="fromSeconds"
                    placeholder="החל מ..."
                    className="form-control col"
                    value={state.fromSeconds}
                    onChange={(e) => setters.setFromSeconds(e.target.value)}
                />
            </div>
            <label className="m-1 col-sm-1">עד</label>
            <div className="col">
                <input
                    type="number"
                    id="toSeconds"
                    name="toSeconds"
                    placeholder="עד"
                    className="form-control col"
                    value={state.toSeconds}
                    onChange={(e) => setters.setToSeconds(e.target.value)}
                />
            </div>
        </div>
    ),
    extension: (
        <div className="form-group row">
            <label htmlFor="extension" className="col-sm-2">שלוחה</label>
            <div className="col">
                <input
                    type="text"
                    id="extension"
                    name="extension"
                    placeholder="הכנס שלוחה"
                    className="form-control"
                    value={state.extension}
                    onChange={(e) => setters.setExtension(e.target.value)}
                />
            </div>
        </div>
    ),
    messageName: (
        <div className="form-group row">
            <label htmlFor="messageName" className="col-sm-2">שם הודעה</label>
            <div className="col">
                <input
                    type="text"
                    id="messageName"
                    name="messageName"
                    placeholder="הכנס שם הודעה"
                    className="form-control"
                    value={state.messageName}
                    onChange={(e) => setters.setMessageName(e.target.value)}
                />
            </div>
        </div>
    ),
    identityNumber: (
        <div className="form-group row">
            <label htmlFor="identityNumber" className="col-sm-2">מספר זהות</label>
            <div className="col">
                <input
                    type="text"
                    id="identityNumber"
                    name="identityNumber"
                    placeholder="הכנס מספר זהות"
                    className="form-control"
                    value={state.identityNumber}
                    onChange={(e) => setters.setIdentityNumber(e.target.value)}
                />
            </div>
        </div>
    ),
    singleKlass: (
        <div className="form-group row">
            <label htmlFor="klass" className="col-sm-2">כיתה</label>
            <div className="col">
                <TypeAhead
                    multiple={false}
                    id="klass"
                    placeholder={"בחר כיתה..."}
                    value={state.klass}
                    setValue={setters.setKlass}
                    getOptions={getKlassList}
                />
            </div>
        </div>
    ),
    singleMegama: (
        <div className="form-group row">
            <label htmlFor="megama" className="col-sm-2">מגמה</label>
            <div className="col">
                <TypeAhead
                    multiple={false}
                    id="megama"
                    placeholder={"בחר מגמה..."}
                    value={state.megama}
                    setValue={setters.setMegama}
                    getOptions={getMegamaList}
                />
            </div>
        </div>
    ),
    singleStudent: (
        <div className="form-group row">
            <label htmlFor="student" className="col-sm-2">תלמידה</label>
            <div className="col">
                <TypeAhead
                    id="student"
                    multiple={false}
                    placeholder={"בחר תלמידה..."}
                    value={state.student}
                    setValue={setters.setStudent}
                    getOptions={(query) => getStudentList(query)}
                />
            </div>
        </div>
    ),
    singleLesson: (
        <div className="form-group row">
            <label htmlFor="lesson" className="col-sm-2">שיעור</label>
            <div className="col">
                <TypeAhead
                    id="lesson"
                    multiple={false}
                    placeholder={"בחר שיעור..."}
                    value={state.lesson}
                    setValue={setters.setLesson}
                    getOptions={(query) => getLessonList(query)}
                />
            </div>
        </div>
    ),
});