import { useState, useEffect } from 'react';

export const useFilterState = (type, params) => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [klass, setKlass] = useState([]);
    const [lesson, setLesson] = useState([]);
    const [lessonCategory, setLessonCategory] = useState([]);
    const [fromSeconds, setFromSeconds] = useState("");
    const [toSeconds, setToSeconds] = useState("");
    const [extension, setExtension] = useState("");
    const [messageName, setMessageName] = useState("");
    const [identityNumber, setIdentityNumber] = useState("");
    const [name, setName] = useState("");
    const [grade, setGrade] = useState("");
    const [classNum, setClassNum] = useState("");
    const [megama, setMegama] = useState([]);
    const [student, setStudent] = useState([]);
    const [allLessons, setAllLessons] = useState(false);

    useEffect(() => {
        setFromDate(params.fromDate || "");
        setToDate(params.toDate || "");
        setKlass(params.klass || []);
        setLesson(params.lesson || []);
        setLessonCategory(params.lessonCategory || []);
        setFromSeconds(params.fromSeconds || "");
        setToSeconds(params.toSeconds || "");
        setExtension(params.extension || "");
        setMessageName(params.messageName || "");
        setIdentityNumber(params.identityNumber || "");
        setName(params.name || "");
        setGrade(params.grade || "");
        setClassNum(params.classNum || "");
        setMegama(params.megama || []);
        setStudent(params.student || []);
        setAllLessons(params.allLessons || false);
    }, [params]);

    const getDataToSubmit = () => ({
        fromDate,
        toDate,
        klass,
        lesson,
        lessonCategory,
        fromSeconds,
        toSeconds,
        extension,
        messageName,
        identityNumber,
        name,
        grade,
        classNum,
        megama,
        student,
        allLessons,
    });

    return {
        state: {
            fromDate,
            toDate,
            klass,
            lesson,
            lessonCategory,
            fromSeconds,
            toSeconds,
            extension,
            messageName,
            identityNumber,
            name,
            grade,
            classNum,
            megama,
            student,
            allLessons,
        },
        setters: {
            setFromDate,
            setToDate,
            setKlass,
            setLesson,
            setLessonCategory,
            setFromSeconds,
            setToSeconds,
            setExtension,
            setMessageName,
            setIdentityNumber,
            setName,
            setGrade,
            setClassNum,
            setMegama,
            setStudent,
            setAllLessons,
        },
        getDataToSubmit
    };
};