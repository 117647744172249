import React from "react";
import Filter from "./Filter";

export default function FilterGraph({ getData, reportData, params, filterFields, isHidePdfButton }) {
    return (
        <Filter 
            type="graph"
            getData={getData}
            reportData={reportData}
            params={params}
            filterFields={filterFields}
            isHidePdfButton={isHidePdfButton}
            isHideEmailButton={true}
            isHideExcelButton={true}
        />
    );
}
