import React from "react";
import Filter from "./Filter";

export default function FilterTable({
    params,
    filterFields,
    getData,
    reportData,
    sendEmailData,
    isHideEmailButton,
    isHidePdfButton,
    isHideExcelButton,
}) {
    return (
        <Filter 
            type="table"
            getData={getData}
            reportData={reportData}
            sendEmailData={sendEmailData}
            params={params}
            filterFields={filterFields}
            isHideEmailButton={isHideEmailButton}
            isHidePdfButton={isHidePdfButton}
            isHideExcelButton={isHideExcelButton}
        />
    );
}
