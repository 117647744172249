import React, { useState } from "react";
import EmailReportPopup from "../EmailReportPopup";
import { createFields } from "./FilterFields";
import { useFilterState } from "./useFilterState";

const defaultHideButtons = {
    isHideEmailButton: false,
    isHidePdfButton: false, 
    isHideExcelButton: false
};

export default function Filter({
    type = "table",
    params,
    filterFields,
    getData,
    reportData,
    sendEmailData,
    ...buttonProps
}) {
    const { isHideEmailButton, isHidePdfButton, isHideExcelButton } = { ...defaultHideButtons, ...buttonProps };
    const [isEmailReportOpen, setIsEmailReportOpen] = useState(false);

    const { state, setters, getDataToSubmit } = useFilterState(type, params);
    const fields = createFields(state, setters);

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        getData(getDataToSubmit());
    };

    const handleClear = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getData();
    };

    const handleEmailReportClose = (isSend, recipient, format) => {
        if (isSend && sendEmailData) {
            sendEmailData(recipient, { ...params, format });
        }
        setIsEmailReportOpen(false);
    };

    const handlePdfReport = (e) => {
        e.preventDefault();
        e.stopPropagation();
        reportData({ ...params, format: "PDF" });
    };

    const handleExcelReport = (e) => {
        e.preventDefault();
        e.stopPropagation();
        reportData({ ...params, format: "EXCEL" });
    };

    return (
        <>
            {filterFields.length > 0 && (
                <form className="p-2 jumbotron container">
                    {filterFields.map((item) => fields[item])}
                </form>
            )}
            <div className="d-flex mb-4">
                {filterFields.length > 0 ? (
                    <>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                            {type === "table" ? "סנן נתונים" : "הצג גרפים"}
                        </button>
                        &nbsp;
                        <button type="clear" className="btn btn-default mr-auto" onClick={handleClear}>
                            נקה סינון
                        </button>
                    </>
                ) : (
                    <button type="submit" className="btn btn-primary mr-auto" onClick={handleSubmit}>
                        רענן נתונים
                    </button>
                )}
                <EmailReportPopup 
                    isOpen={isEmailReportOpen} 
                    onClose={handleEmailReportClose}
                    isHidePdfButton={isHidePdfButton}
                    isHideExcelButton={isHideExcelButton} 
                />
                {!isHideEmailButton && (
                    <button
                        className="btn btn-outline-dark"
                        onClick={() => setIsEmailReportOpen(true)}
                        title="שלח את הנתונים במייל">
                        Email <i className="fa fa-envelope"></i>
                    </button>
                )}
                {!isHidePdfButton && (
                    <button 
                        className="btn btn-outline-dark ml-2" 
                        onClick={handlePdfReport} 
                        title="יצא את הנתונים לPDF">
                        Pdf <i className="fa fa-file-pdf"></i>
                    </button>
                )}
                {!isHideExcelButton && (
                    <button
                        className="btn btn-outline-dark ml-2"
                        onClick={handleExcelReport}
                        title="יצא את הנתונים לאקסל">
                        Excel <i className="fa fa-file-excel"></i>
                    </button>
                )}
            </div>
        </>
    );
}